import WebConfig, { SiteConfig } from '@tipalti-aphub/web-config';

const dsn = 'https://103fbcf5b68441bb9f75acf38fe9cff6@o400669.ingest.sentry.io/4504920855085056';

export const Environments: Record<string, string> = {
    UNKNOWN: 'UNKNOWN',
    DEV: 'DEV',
    RC: 'RC',
    PERF: 'PERF',
    SBOX: 'SBOX',
    SBOX_EKS: 'SBOX_EKS',
    PROD: 'PROD',
    PROD_EKS: 'PROD_EKS',
};

class ConfigService {
    // TODO: any is bad
    async getConfig(): Promise<Record<string, any>> {
        const loginConfig: SiteConfig = await WebConfig.getAll({
            domain: 'login',
            apiEndpoint: '/gateway/site-config',
        });

        /* c8 ignore start */
        // TODO: Fallback - remove once web-config is updated for all environments
        const config: Record<string, any> = {
            [Environments.UNKNOWN]: {
                sentry: {
                    dsn,
                    disabled: true,
                },
                hubUrl: 'https://hub.dev.tipalti-dev.com',
                translationServiceUrl: 'https://translations-service.dev.tipalti-dev.com',
                localeEnabled: true,
                localePseudoEnabled: true,
                redirectToSsoProviderEnabled: false,
            },
            [Environments.DEV]: {
                sentry: {
                    dsn,
                    tracesSampleRate: 0.1,
                    replaysSessionSampleRate: 0.0,
                    replaysOnErrorSampleRate: 0.0,
                },
                hubUrl: 'https://hub.dev.tipalti-dev.com',
                translationServiceUrl: 'https://translations-service.dev.tipalti-dev.com',
                localeEnabled: true,
                localePseudoEnabled: true,
                redirectToSsoProviderEnabled: false,
            },
            [Environments.RC]: {
                sentry: {
                    dsn,
                    tracesSampleRate: 0.1,
                    replaysSessionSampleRate: 0.0,
                    replaysOnErrorSampleRate: 0.0,
                },
                hubUrl: 'https://hub.rc.tipalti-dev.com',
                translationServiceUrl: 'https://translations-service.rc.tipalti-dev.com',
                localeEnabled: false,
                localePseudoEnabled: true,
                redirectToSsoProviderEnabled: false,
            },
            [Environments.PERF]: {
                sentry: {
                    dsn,
                    tracesSampleRate: 0.1,
                    replaysSessionSampleRate: 0.0,
                    replaysOnErrorSampleRate: 0.0,
                },
                hubUrl: 'https://hub.perf.tipalti-dev.com',
                translationServiceUrl: 'https://translations-service.perf.tipalti-dev.com',
                localeEnabled: false,
                localePseudoEnabled: false,
                redirectToSsoProviderEnabled: false,
            },
            [Environments.SBOX]: {
                sentry: {
                    dsn,
                    tracesSampleRate: 0.2,
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                },
                hubUrl: 'https://hub.sandbox.tipalti.com',
                translationServiceUrl: 'https://translations-service.sbox.tipalti.com',
                localeEnabled: false,
                localePseudoEnabled: false,
                redirectToSsoProviderEnabled: false,
            },
            [Environments.SBOX_EKS]: {
                sentry: {
                    dsn,
                    tracesSampleRate: 0.2,
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                },
                // url is different from regular SBOX - for EKS
                hubUrl: 'https://hub.sbox.tipalti.com',
                translationServiceUrl: 'https://translations-service.sbox.tipalti.com',
                localeEnabled: false,
                // same as regular SBOX for the environment
                environment: Environments.SBOX,
                localePseudoEnabled: false,
                redirectToSsoProviderEnabled: false,
            },
            [Environments.PROD]: {
                sentry: {
                    dsn,
                    tracesSampleRate: 0.3,
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                },
                hubUrl: 'https://hub.tipalti.com',
                translationServiceUrl: 'https://translations-service.production.tipalti.com',
                localeEnabled: false,
                localePseudoEnabled: false,
                redirectToSsoProviderEnabled: false,
            },
            [Environments.PROD_EKS]: {
                sentry: {
                    dsn,
                    tracesSampleRate: 0.3,
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                },
                // url is different from regular PROD - for EKS
                hubUrl: 'https://hub.production.tipalti.com',
                translationServiceUrl: 'https://translations-service.production.tipalti.com',
                localeEnabled: false,
                // same as regular PROD for the environment
                environment: Environments.PROD,
                localePseudoEnabled: false,
                redirectToSsoProviderEnabled: false,
            },
        };

        const env = this.getEnvByHostname();

        return Promise.resolve({
            sentry: {
                dsn: loginConfig?.SentryDSN ?? dsn,
                tracesSampleRate:
                    loginConfig?.SentrySampleRate ?? config[env].sentry.tracesSampleRate,
                replaysSessionSampleRate:
                    loginConfig?.SentryReplaySampleRate ??
                    config[env].sentry.replaysSessionSampleRate,
                replaysOnErrorSampleRate:
                    loginConfig?.SentryReplayErrorSampleRate ??
                    config[env].sentry.replaysOnErrorSampleRate,
            },
            hubUrl: loginConfig?.HubUrl ?? config[env].hubUrl,
            environment: config[env].environment ?? env,
            iframeEnabled: !!loginConfig?.LoginIframeEnabled,
            translationServiceUrl:
                loginConfig?.TranslationsServiceUrl ?? config[env].translationServiceUrl,
            localeEnabled: loginConfig?.LocaleEnabled ?? config[env].localeEnabled,
            localePseudoEnabled:
                loginConfig?.LocalePseudoEnabled ?? config[env].localePseudoEnabled,
            redirectToSsoProviderEnabled:
                loginConfig?.RedirectToSsoProviderEnabled ??
                config[env].redirectToSsoProviderEnabled,
        });
        /* c8 ignore stop */
    }

    getEnvByHostname(): string {
        const hostname = window.location.hostname;
        const envToHosts: Record<string, string[]> = {
            [Environments.UNKNOWN]: ['localhost'],
            [Environments.DEV]: ['dev.payrad.com', 'dev.tipalti-dev.com'],
            [Environments.RC]: ['rc.tipalti-dev.com'],
            [Environments.PERF]: ['perf.tipalti-dev.com'],
            [Environments.SBOX]: ['sandbox.tipalti.com'],
            [Environments.SBOX_EKS]: ['sbox.tipalti.com'],
            [Environments.PROD]: ['tipalti.com'],
            [Environments.PROD_EKS]: ['production.tipalti.com'],
        };

        for (const env in envToHosts) {
            for (const host of envToHosts[env]) {
                if (hostname.includes(host)) {
                    return env as string;
                }
            }
        }
        return Environments.UNKNOWN;
    }
}

export default new ConfigService();
