<template>
    <div
        class="new-tip-spinner"
        :class="{ 'new-tip-spinner--small': props.small }"
    >
        <div class="new-tip-spinner__wrapper">
            <div class="new-tip-spinner__inner">
                <svg viewBox="-25 0 100 100">
                    <path
                        id="svg-tipalti_t"
                        xmlns="http://www.w3.org/2000/svg"
                        data-name="SVG Tipalti T"
                        d="M23.422,81.976c-9.938,0-13.967-5.641-12.356-15.31l4.566-25.249H6.5l1.612-9.938h9.132L19.93,15.9H32.017L29.331,31.479H41.687l-1.612,9.938H27.72l-4.3,24.174c-.806,3.76.806,5.372,4.566,5.372a42.108,42.108,0,0,0,7.789-.537L33.9,80.365a35.456,35.456,0,0,1-10.475,1.612"
                        fill="#031729"
                    />
                </svg>
                <div class="new-tip-spinner__line">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    const props = defineProps({
        small: {
            type: Boolean,
            default: false,
        },
    });
</script>
<style lang="scss">
    $border-color: #ffbc00;
    $border-width: 3px;
    $spinner-dimension: 50px;

    .new-tip-spinner {
        & {
            position: relative;
            height: $spinner-dimension;
            width: $spinner-dimension;
        }

        &--small {
            & {
                height: math.div($spinner-dimension, 2);
                width: math.div($spinner-dimension, 2);
            }
            .new-tip-spinner__inner {
                height: math.div($spinner-dimension, 2);
                width: math.div($spinner-dimension, 2);
            }
            .new-tip-spinner__line div {
                border-width: math.div($border-width, 2);
            }
        }
        &__wrapper {
            background-color: transparent;
            bottom: 0;
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
        &__inner {
            height: $spinner-dimension;
            width: $spinner-dimension;
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        & svg {
            position: absolute;
            height: 90%;
            width: 90%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &__line {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            & div {
                box-sizing: border-box;
                display: block;
                position: absolute;
                width: 90%;
                height: 90%;
                margin: 5%;
                border: $border-width solid $border-color;
                border-radius: 50%;
                animation: new-tip-spinner 1.5s cubic-bezier(0.5, 0, 0.3, 1) infinite;
                border-color: $border-color transparent transparent transparent;

                &:nth-child(1) {
                    animation-delay: -0.45s;
                }
                &:nth-child(2) {
                    animation-delay: -0.3s;
                }
                &:nth-child(3) {
                    animation-delay: -0.15s;
                }
            }
        }
    }
    @keyframes new-tip-spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
