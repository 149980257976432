import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "login-app__tip-spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TipSpinner = _resolveComponent("TipSpinner")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["login-app", {
            'i18next-ready': _ctx.$i18next?.isInitialized,
        }])
  }, [
    (!_ctx.initialized)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_TipSpinner)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.initialized)
      ? (_openBlock(), _createBlock(_component_RouterView, { key: 1 }, {
          default: _withCtx(({ Component, route }) => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: route.path,
              class: "login-app__component"
            }))
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}