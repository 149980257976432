/**
 * Returns a value by a case-insensitive key
 * @param obj
 * @param key
 */
export const getProperty = (obj: Record<string, unknown>, key: string): unknown => {
    if (!obj || !key) return undefined;
    const caseInsensitiveKey = Object.keys(obj).find(k => k.toLowerCase() === key.toLowerCase());
    return caseInsensitiveKey && obj[caseInsensitiveKey];
}

/**
 * get cookie by name
 * @param name
 */
export const getCookie = (name: string) => {
    const match: RegExpMatchArray | null = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)'));
    return match && match[1];
}

/**
 * set cookie
 * @param name
 * @param value
 * @param days
 */
export const setCookie = (name: string, value: string = '', days: number = 7) => {
    let expires: string = '';
    if (days) {
        const date = new Date();
        const daysInMilliseconds = days * 24 * 60 * 60 * 1000;
        date.setTime(date.getTime() + (daysInMilliseconds));
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
}