import { localize, setLocale } from '@vee-validate/i18n';
import { is_not, regex, required } from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';
import { getI18NextInstance } from '../setup/i18n';
import { TranslationBundles } from '@tipalti/web-constants'

export function setupValidation() {
    defineRule('required', required);
    defineRule('is_not', is_not);
    defineRule('regex', regex);
    // @ts-expect-error - issue where it thinks we don't have an array in the second param
    defineRule('confirmed', (value, [target], ctx) => {
        return value === ctx.form[target];
    });

    defineRule('sameAsOld', (value, [target], ctx) => {
        return value !== ctx.form[target];
    });

    // Checks also for special characters
    defineRule('newPasswordComplexity', (value: string) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\p{L}\d]).{8,}$/u.test(value);
    });

    // Does not check for special characters (for current password in password expired page)
    defineRule('complexity', (value: string) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value);
    });

    defineRule('email', (value: string) => {
        return /^$|^[-A-Za-z0-9~!$%^&*_=+}{'?]+(\.[-A-Za-z0-9~!$%^&*_=+}{'?]+)*@([A-Za-z0-9_][-A-Za-z0-9_]*(\.[-A-Za-z0-9_]+)*\.([A-Za-z]{2,50})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i.test(
            value
        );
    });
}

export const updateVeeValidateLocalization = async (locale: string) => {
    try {
        const i18next = getI18NextInstance();
        const resources = i18next.getResourceBundle(
            locale,
            TranslationBundles.login
        );
        
        configure({
            generateMessage: localize(locale, resources.validations),
        });
        
        setLocale(locale);
    } catch (error) {
        console.warn('Error updating VeeValidate localization: ', error);
    }
};
