import * as Sentry from '@sentry/vue';
import {HttpClient, ExtraErrorData} from '@sentry/integrations';
import {createApp} from 'vue';
import {createPinia} from 'pinia';
import router from './lib/router';
import {setupI18n} from './setup/i18n';
import {setupGlobalComponents} from './setup/global-components';
import {setupFontAwesome} from './setup/font-awesome';
import '@tipalti/global-styles/src/index.scss';
import './style.css';
import App from './App.vue';
import configService from './services/configService';
import VueClickAway from "vue3-click-away"

const app = createApp(App);

// TODO: any is bad
const config: Record<string, any> = await configService.getConfig();
const {sentry, environment} = config;

Sentry.init({
    app,
    attachStacktrace: true,
    autoSessionTracking: true,
    release: process.env.RELEASE_ID,
    ...sentry,
    environment,
    sendDefaultPii: true, // capture headers and cookies
    tracingOptions: {
        trackComponents: true,
        timeout: 2000, // default
        hooks: ['activate', 'create', 'destroy', 'mount', 'update'], // track all lifecycle hooks
    },
    integrations: [
        new HttpClient(),
        new ExtraErrorData(),
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router, {
                routeLabel: 'name',
            }),
        }),
        new Sentry.Replay({
            maskAllText: false, // none of the text should be private, if so we need to mask it
            blockAllMedia: false,
            maskAllInputs: true,
            mask: ['.sentry-mask', '[data-sentry-mask]', '[data-private]'],
            block: ['.sentry-block', '[data-sentry-block]', '[data-private]'],
            unmask: ['.sentry-unmask', '[data-sentry-unmask]', '[data-public]'],
            unblock: ['.sentry-unblock', '[data-sentry-unblock]', '[data-public]'],
        }),
    ],
});

Sentry.addEventProcessor((event) => {
    event.tags = event.tags || {};
    event.tags['login-type'] = (event.contexts?.login?.type || 'unknown') as string;
    return event;
});

Sentry.addEventProcessor((event) => {
    // modify HTTP client errors groupings
    if (event.exception?.values?.[0]?.mechanism?.type === 'http.client') {
        // init tags if they don't exist
        event.tags = event.tags || {};

        // set the error type to http
        event.tags['error-type'] = 'http';
        event.tags['http-error'] = true;

        // take the tags url and strip the domain
        const url = event.tags?.url as string;

        if (url) {
            // take off the hostname
            const path = url.replace(/^[a-z]{4,5}:\/{2}[a-z]{1,}:[0-9]{1,4}.(.*)/, '$1'); // http or https
            // get the status code
            const statusCode = event.contexts?.response?.status as string;

            // use path as the http-url tag + status code
            event.tags['http-url'] = path;
            event.tags['http-status-code'] = statusCode;

            // set the fingerprint to {{ default }} + url path + status code
            event.fingerprint = ['{{ default }}', path, statusCode];

            // change the title to include the path
            event.message = `${event.message} on path: ${path}`;
        }
    }

    return event;
});

app.use(router);
app.use(VueClickAway)
await setupI18n(app);
setupGlobalComponents(app);
setupFontAwesome(app);

app.use(createPinia()).mount('#app');
